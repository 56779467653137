import { Link } from "gatsby";
import React, {useState} from "react";
import {useTrail, animated, useSpring} from 'react-spring'
import { ContainerFluid, Row, Col } from '../../UI/Grid/Grid';
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';
import * as classes from './Header.module.scss';

const Header = ( ) => {

  const [showSidebar, setShowSidebar] = useState(false);

  const togglerHandler = () => {
      setShowSidebar (!showSidebar);
  }

  let sidebarClasses = showSidebar? `${classes.MenuExpand} ${classes.Show}`:
                                    `${classes.MenuExpand}`;
  let menuIconClass = showSidebar? `${classes.CloseIcon}` : '';

  /*
   * Animate the menu button to X close
  */
  const first = useSpring({
    transform: showSidebar
      ? "translate(5px, 32px) rotate(-45deg)"
      : "translate(2px, 7px) rotate(0deg)"
  });
  const second = useSpring({
    transform: showSidebar
      ? "translate(10px, 4px) rotate(45deg)"
      : "translate(2px, 19px) rotate(0deg)"
  });
  const third = useSpring({
    transform: showSidebar
      ? "translate(10px, 4px) rotate(45deg)"
      : "translate(2px, 31px) rotate(0deg)"
  });

const menuToggler =
  <svg
      onClick={togglerHandler}
      width="40"
      height="32"
      viewBox="0 0 44 44"
      fill="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.rect className={menuIconClass} width="40" height="4" rx="2" style={first} />
      <animated.rect className={menuIconClass} width="40" height="4" rx="2" style={second} />
      <animated.rect className={menuIconClass} width="40" height="4" rx="2" style={third} />
  </svg>

/*
 * Sidebar menu
*/
const sidebarData = [
  {
    link: "/#home",
    text:  "الرئيسية "
  },
  {
    link: "/#aboutus",
    text: "من نحن"
  },
  {
    link: "/#services",
    text: "خدماتنا"
  },
  {
    link: "/#contactus",
    text: "تواصل معنا"
  }
]

const sidebar =
  <div className={sidebarClasses}>
    <ContainerFluid>
      <div className={classes.Content}>
        <div className={`${classes.CLoseMenu} p-3`}>
          {menuToggler}
        </div>
        <ul>
          {sidebarData.map( sidebarElement => (
            <li
              key={sidebarElement.link}>
              <div>
                <Link
                  className={classes.SidebarNavItem}
                  to={sidebarElement.link}
                  onClick={togglerHandler}>
                  <span className={classes.SidebarNavText}>
                    {sidebarElement.text}
                  </span>
                </Link>
              </div>
          </li>
          ))}
        </ul>
      </div>
      <div className={`${classes.SocialMedia} pb-3 pr-5 pl-5`}>
        <div className="p-5">
        <span className='pl-5'>
          <FaFacebook className={classes.Icon}/>
        </span>
        <FaLinkedinIn className={classes.Icon}/>
        </div>
      </div>
    </ContainerFluid>
  </div>

  return (
    <header className={classes.HeaderContainer}>
      <ContainerFluid>
        <div className={`${classes.HeaderMenu} p-3`}>
          {menuToggler}
        </div>
        <h4 className={`${classes.Logo} text-left p-3`}>
          <Link to="/#home">
            ELKHOLY <span className={classes.LogoExt}>LAW FIRM</span>
          </Link>
        </h4>
        {sidebar}
      </ContainerFluid>
    </header>
  )
}

export default Header
