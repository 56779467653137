import React from 'react';

export const Row = (props) => (
  <div {...props} className={`row ${props.className? props.className : ''}`}>
    {props.children}
  </div>
);

export const Col = (props) => (
  <div {...props} className={`col ${props.className? props.className : ''}`}>
    {props.children}
  </div>
);

export const ContainerFluid = (props) => (
  <div {...props} className={`container-fluid ${props.className? props.className : ''}`}>
    {props.children}
  </div>
);

export const Container = (props) => (
  <div {...props} className={`container ${props.className? props.className : ''}`}>
    {props.children}
  </div>
);
